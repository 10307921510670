import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
import { useLocation} from "@reach/router";
import PropertyDetailsComponents from "@Components/PropertyDetails/PropertyDetails"
import SEO from "../Components/seo";
import axios from 'axios';
import { Helmet } from "react-helmet";
import '../pages/PropertyDetails/PropertyDetails.scss';
import { Container } from 'react-bootstrap';
import NotFoundPage from '../pages/404';
import { useNavigate } from '@reach/router';
import { isSold } from '@Components/common/site/utils';

const PropertyDetails = (props) => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    showMenu: false
  })

  const handlerClick = () => {
    // console.log('okkk')
    setState({ ...state, showMenu: !state.showMenu })
  }

  //   
  const [property_details, setPropertyData] = useState('');
  const [loading, setloading] = useState(true);
  const [metaTitle, setmetaTitle] = useState('');
  const [metaDesc, setmetaDesc] = useState('');
  const [images1, setimages1] = useState([]);

  useEffect(() => {
    getPropertyData();
    window.scrollTo(0, 0);
  }, []);

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");
  id = id.replace("undefined", "");

  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  const getPropertyData = async () => {
    const property_details = await axios.get(`${process.env.GATSBY_STRAPI_SRC}/properties/${id}`, strapiconfig).then((result) => {
      //console.log("resultdataaaa",result.data)
      if (result.data.publish === true) {
        setPropertyData(result.data);
        sessionStorage.setItem('data', JSON.stringify(result.data))
        sessionStorage.setItem('property_url', pageurl)
        setmetaTitle(result.data.title + ' in ' + result.data.display_address)
        setmetaDesc('Book a viewing for this ' + result.data.title + ' in ' + result.data.display_address + ' with Bentley Hurst at ' + result.data.price + '. Contact Bentley Hurst, and get assisted for all your property needs.')
        var imagesArr = []
        for (var i = 0; i < result.data.images.length; i++) {
          imagesArr.push(result.data.images[i].url)
        }
        setimages1(imagesArr)
        setloading(false)
      } else {
        navigate("/404")
      }
    }).catch((err) => {
      setloading(false)
      // console.log('property_details error', err.response.status);
      setPropertyData('NotFound');
    });
  };
  //

  // ggfx
  let processedImages = JSON.stringify({});
  if (property_details?.imagetransforms?.images_Transforms) {
    processedImages = property_details.imagetransforms.images_Transforms;
  }
  // ggfx

  var metaTitleNew = metaTitle.replace("bedroom", "Bedroom").replace("apartment", "Apartment").replace("house", "House").replace("townhouse", "Townhouse").replace("sale", "Sale").replace("rent", "Rent");
  var { pathname } = useLocation()
  var currentUrl = ""
  currentUrl = process.env.GATSBY_SITE_URL + pathname
  return (
    <>
      {
        property_details !== "NotFound" ?
          <div className={state.showMenu ? "wrapper header-style open-search-block" : "wrapper header-style property-details"}>
            <SEO title={metaTitle ? metaTitleNew : ''} description={metaDesc ? metaDesc : ''} url={pageurl}
              image={property_details.images?.length > 0 ? property_details.images[0].url : ''}
            />
            <Helmet>
            {property_details.title && (
              <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org",
                  "@type": "Residence",
                  "name": "Book a viewing for this ${property_details.title} in ${property_details.display_address} with Bentley Hurst at ${property_details.price}. Contact Bentley Hurst, and get assisted for all your property needs.",
                  "description": "${property_details.description}",
                  "url": "${currentUrl}",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Manchester",
                    "addressCountry": "UK"
                  },
                  "image": "${property_details.images?.length > 0 ? property_details.images[0].url : ''}",
                  "numberOfRooms": ${property_details.bedroom},
                  "offers": {
                    "@type": "Offer",
                    "priceCurrency": "GBP",
                    "price": ${property_details.price},
                    "availability": "http://schema.org/InStock"
                  }
                }
              `}</script>
            )}
            </Helmet>
            {/* {JSON.stringify(property_details.images[0].url)} */}

            <Header showMenu={state.showMenu} handlerClick={handlerClick} />
            {loading ? <div className="propertydetail gql-loading-screen">
              <Container>
                <div className="gql-loading"><div className="dot-pulse"></div></div>
              </Container>
            </div> :
              <PropertyDetailsComponents
                property_search_type={property_details.search_type}
                property_building_type={property_details.building}
                property_area={property_details.area}
                property_display_address={property_details.display_address}
                property_price={property_details.price}
                property_title={property_details.title}
                property_bedroom={property_details.bedroom}
                property_bathroom={property_details.bathroom}
                property_status={property_details.status}
                property_reception={property_details.reception}
                property_description={property_details.description}
                property_long_description={property_details.long_description}
                property_negotiator_details={property_details.crm_negotiator_id}
                property_images={property_details.images}
                property_image_id={property_details._id}
                property_floorplan={property_details.floorplan !== undefined ? property_details.floorplan : ''}
                property_brochure={property_details.brochure !== undefined ? property_details.brochure : ''}
                processedImages={processedImages}
                property_id={property_details.id}
                property_department={property_details.department}
                property_latitude={property_details.latitude}
                property_longitude={property_details.longitude}
                postcode={property_details.address !== undefined ? property_details.address.postcode : ''}
                property_video_tour={property_details.virtual_tour}
                available_bedrooms={property_details.available_bedrooms}
              />
            }
            
            <Footer popularsearch='propertydetails' propertydetails={property_details} />
            <div className="fixed-btns d-flex align-items-center justify-content-between d-lg-none property-details-mobile-btn">
              {!isSold(property_details.status) && (
                <>
                  <Link to="/book-a-viewing" className="dark-bg btn btn-primary d-md-none">Book a viewing</Link>
                  <Link to="/book-a-viewing" className="dark-bg btn btn-primary d-none d-md-inline-block">Book a viewing</Link>
                </>
              )}
              
              <a href="tel:01615430310" className="dark-bg btn btn-secondry">Call Us</a>
              {/* <Link to="#" className="dark-bg btn btn-secondry">Call Us</Link> */}
            </div>
          </div>
          :
          <Router>
            <Switch>
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
      }
    </>
  )
}



export default PropertyDetails